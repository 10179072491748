import posthog from 'posthog-js'

interface BrowserInfo {
  name: string
  version: string
}
  
interface DeviceInfo {
  type: 'mobile' | 'tablet' | 'desktop'
  browser: BrowserInfo
  os: string
  osVersion: string
}

interface AuthEventProperties {
  uid?: string;
  user_id?: string;
  error_message?: string;
  device_type?: string;
  browser_name?: string;
  browser_version?: string;
  os_name?: string;
  os_version?: string;
}

function getDeviceInfo(): DeviceInfo {
  const ua = window.navigator.userAgent

  // Detectar navegador y versión
  const browser: BrowserInfo = { name: 'unknown', version: 'unknown' }
  if (ua.includes('Firefox/')) {
    browser.name = 'Firefox'
    browser.version = ua.split('Firefox/')[1].split(' ')[0]
  } else if (ua.includes('Chrome/')) {
    browser.name = 'Chrome'
    browser.version = ua.split('Chrome/')[1].split(' ')[0]
  } else if (ua.includes('Safari/') && !ua.includes('Chrome')) {
    browser.name = 'Safari'
    browser.version = ua.split('Version/')[1].split(' ')[0]
  } else if (ua.includes('Edg/')) {
    browser.name = 'Edge'
    browser.version = ua.split('Edg/')[1].split(' ')[0]
  }

  // Detectar OS y versión
  let os = 'unknown'
  let osVersion = 'unknown'
  if (ua.includes('Windows')) {
    os = 'Windows'
    osVersion = ua.split('Windows NT ')[1]?.split(';')[0] || 'unknown'
  } else if (ua.includes('Mac OS X')) {
    os = 'MacOS'
    osVersion = ua.split('Mac OS X ')[1]?.split(')')[0].replace(/_/g, '.') || 'unknown'
  } else if (ua.includes('Android')) {
    os = 'Android'
    osVersion = ua.split('Android ')[1]?.split(';')[0] || 'unknown'
  } else if (ua.includes('iOS')) {
    os = 'iOS'
    osVersion = ua.split('OS ')[1]?.split(' ')[0].replace(/_/g, '.') || 'unknown'
  } else if (ua.includes('Linux')) {
    os = 'Linux'
    osVersion = 'unknown'
  }

  // Detectar tipo de dispositivo
  let type: DeviceInfo['type'] = 'desktop'
  if (/Mobile|Android|iPhone/i.test(ua)) {
    type = 'mobile'
  } else if (/iPad|Tablet/i.test(ua)) {
    type = 'tablet'
  }

  return {
    type,
    browser,
    os,
    osVersion
  }
}

// Función base para enviar eventos
function trackAuthEvent(action: string, properties: AuthEventProperties) {
  const deviceInfo = getDeviceInfo()
  if (properties.uid) {
    posthog.identify(properties.uid)
  } else {
    console.error('No se encontró el providerId para identificar al usuario.');
  }
  posthog.capture(`auth_${action}`, {
    ...properties,
    distinct_id: properties.uid || 'unknown_user',
    device_type: deviceInfo.type,
    browser_name: deviceInfo.browser.name,
    browser_version: deviceInfo.browser.version,
    os_name: deviceInfo.os,
    os_version: deviceInfo.osVersion
  })
}

export const authEvents = {
  /**
   * Trackea el login exitoso incluyendo método, usuario y datos del dispositivo
   * @param uid - ID del usuario
   */
  logIn: (uid: string) => {
    const deviceInfo = getDeviceInfo()
    trackAuthEvent('login', {
      uid,
      user_id: uid,
      // Información del dispositivo
      device_type: deviceInfo.type,
      // Información del navegador
      browser_name: deviceInfo.browser.name,
      browser_version: deviceInfo.browser.version,
      // Información del sistema operativo
      os_name: deviceInfo.os,
      os_version: deviceInfo.osVersion
    })
  },

  /**
   * Trackea errores en el proceso de login
   * @param errorMessage - Mensaje de error
   */
  loginError: (errorMessage: string) => {
    const deviceInfo = getDeviceInfo()
    trackAuthEvent('login_error', {
      error_message: errorMessage,
      // Información del dispositivo
      device_type: deviceInfo.type,
      // Información del navegador
      browser_name: deviceInfo.browser.name,
      browser_version: deviceInfo.browser.version,
      // Información del sistema operativo
      os_name: deviceInfo.os,
      os_version: deviceInfo.osVersion
    })
  }
}